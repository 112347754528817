<template>
  <div>
    <div class="title">Edit Mapping Entry</div>
    <div class="all row mt-4">
      <div class="col-6">
        <p class="col-4">Source Email</p>
        <b-form-input
          v-model="sourceEmail"
          type="text"
          placeholder="Source Email"
          class="shape col-9"
        >
        </b-form-input>
      </div>
      <div class="col-6">
        <p class="col-4">Target Email</p>
        <b-form-input
          v-model="targetEmail"
          type="text"
          placeholder="Target Email"
          class="shape col-9"
        >
        </b-form-input>
      </div>
    </div>
    <div class="button mb-5">
      <b-button variant="primary" class="botton" v-on:click="saveMapping()"
        >Save Changes</b-button
      >
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "EditMapping",
  data() {
    return {
      projectId: 0,
      sourceEmail: "",
      targetEmail: "",
    };
  },
  props: {
    mappingId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    api.validateToken().then(
      () => {},
      () => {
        this.$router.push({ path: "/login" });
      }
    );
    this.getUserMapping(this.project, this.mappingId);
  },
  methods: {
     makeToast(textToShow) {
        this.$bvToast.toast(textToShow, {
          title: 'Oops! Something went wrong.' ,
          variant: 'warning',
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    saveMapping() {
      const form = JSON.stringify(this.getSettings());
      api.updateUserMapping(form, this.project, this.mappingId).then(
        () => {
          this.$bvModal.hide("edit-mapping");
        },
        (error) => {
            this.makeToast(error.responseData);
        }
      );
      this.$emit(
        "update-mapping",
        this.mappingId,
        this.sourceEmail,
        this.targetEmail
      );
    },
    getSettings() {
      return {
        SourceEmail: this.sourceEmail,
        TargetEmail: this.targetEmail,
      };
    },
    getUserMapping(projectId, mappingId) {
      api.getUserMapping(projectId, mappingId).then((response) => {
        this.sourceEmail = response.responseData.sourceEmail;
        this.targetEmail = response.responseData.targetEmail;
      });
    },
  },
};
</script>

<style scoped>
.all {
  margin-left: 60px;
}
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.shape {
  width: 480px;
  height: 56px;
}
.botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.button {
  text-align: center;
}
</style>
