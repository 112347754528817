<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="Mapping Table" class="pb-5" /> <br />
      <!-- <div class="plus">
        <div class="plusIn">
          <img src="../assets/upload.png" class="iconPlus" />
          <span> Upload CSV </span>
        </div>
      </div> -->
      <br />
      <div class="bar row">
        <div class="col-4">
          <p class="tabTitle">Source Email</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Target Email</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Actions</p>
        </div>
      </div>

      <div v-for="item in userMapping" :key="item.id" class="bar1 row">
        <div class="col-4">
          <p class="tabText">{{ item.sourceEmail }}</p>
        </div>
        <div class="col-4">
          <p class="tabText">{{ item.targetEmail }}</p>
        </div>
       
         <div class="col-4">
          <div class="buttons">
            <b-icon
              class="bicon touch" data-toggle="tooltip" data-placement="top" title="Edit"
              variant="primary"
              icon="pencil-square"
              aria-hidden="true"
              @click="modalEdit(item.id)" 
            >
            </b-icon>
            <b-icon
              class="bicon touch" data-toggle="tooltip" data-placement="top" title="Delete"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="deleteUser(item.id)"
            >
            </b-icon>
          </div>
        </div>
      </div>

      <div class="bar1 row">
        <div class="col-4">
          <b-form-input
            v-model="newSourceEmail"
            type="text"
            placeholder="Source email"
            class="shape profile"
          ></b-form-input>
        </div>
        <div class="col-4">
          <b-form-input
            v-model="newTargetEmail"
            type="text"
            placeholder="Target email"
            class="shape profile"
          ></b-form-input>
        </div>
        <div class="col-4">
          <div class="buttons">
            <b-icon data-toggle="tooltip" data-placement="top" title="Add"
              class="bicon touch"
              variant="success"
              icon="plus-circle"
              @click="addUser()"
            >
            </b-icon>
            <b-icon
              class="bicon touch" data-toggle="tooltip" data-placement="top" title="Clear"
              variant="secondary"
              icon="x-circle"
              @click="clearFields()"
            >
            </b-icon>
          </div>
        </div>
    
      </div>
    </div>
    <b-modal size="xl" id="edit-mapping" hide-footer>
      <editMapping @update-mapping="updateMapping" :mappingId="mappingId" />
    </b-modal>
  </div>
</template>

<script>
import api from "../api/index";
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import editMapping from "../components/popup/editMapping.vue";

export default {
  name: "UserMapping",
  components: {
    sidebar,
    upbar,
    editMapping,
  },
  data() {
    return {
      logged: false,
      userMapping: [],
      newSourceEmail: "",
      newTargetEmail: "",
      mappingId: null,
    };
  },
  created() {
    //this.getProjects();
  },
  mounted() {
    this.logged = this.$store.getters.isAuthenticated;
    this.loadUserMapping();
  },
  methods: {
    updateMapping(mappingId, sourceEmail, targetEmail) {
      const identifiedMapping = this.userMapping.find(
        (x) => x.id === mappingId
      );
      identifiedMapping.sourceEmail = sourceEmail;
      identifiedMapping.targetEmail = targetEmail;
    },
    clearFields() {
      this.newSourceEmail = "";
      this.newTargetEmail = "";
    },
    addUser() {
      if (this.newSourceEmail != "" && this.newTargetEmail != "") {
        const form = {
          sourceEmail: this.newSourceEmail,
          targetEmail: this.newTargetEmail,
        };
        api.addUserMapping(form, this.$route.params.id).then(() => {
          this.loadUserMapping();
          this.clearFields();
        });
      } else {
        this.makeToast('Cannot add empty values. Please provide a source and target email.');
      }
    },
    makeToast(textToShow) {
        this.$bvToast.toast(textToShow, {
          title: 'Oops! Something went wrong.' ,
          variant: 'warning',
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    modalEdit(itemId) {
      this.mappingId = itemId;
      this.$bvModal.show("edit-mapping");
    },
    deleteUser(userId) {
      api.deleteUserMapping(this.$route.params.id, userId).then(() => {
        this.loadUserMapping();
      });
    },

    loadUserMapping() {
      api.getUserMappings(this.$route.params.id).then((response) => {
        this.userMapping = response.responseData;
      });
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 155px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 25px;
}
.altosio-icon {
  width: 21px;
  height: 36px;
}
.plus {
  margin-right: 40px;
  float: right;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.shape {
  margin-top: 15px;
  margin-left: 32px;
  height: 50px;
}
.blue {
  background: #2a4192;
  border-radius: 5px;
}
</style>
